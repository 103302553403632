import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { PError } from './apiTypes';
import { ApiResultError, FinancialsWidgetChartItemCreateModel } from './clientApi/ClientApiTypes';
import { StandardErrorData } from './components/form/Form';
import { ApiErrors } from './hooks/validation/useValidation';
import { useAccountUser } from './userPermission';
import React from 'react';
import { AccountDocumentStorageViewModel } from './documentApi/DocumentApiTypes';

type Ordinal = {
    ordinal: number;
};

export type ReactChildren = React.JSX.Element | string | undefined | null | boolean | ReactChildren[];

/**
 * Constructs a type of T and set properties K to required
 * @example
 * type Props = {
 *     a?: number;
 *     b?: string;
 * }
 *
 * type PropsWithRequired = WithRequired<Props, 'a'>;
 * // type PropsWithRequired = {
 * //     a: number;
 * //     b?: string;
 * // }
 */
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

/**
 * Extracts the type of elements in a tuple/array.
 *
 * @template T - The tuple/array type.
 * @returns The type of elements in the tuple/array if T is an array type, otherwise never.
 *
 * @example
 * // Returns number
 * type Result = TupleTypes<[number, number, number]>;
 */
export type TupleTypes<T> = T extends (infer U)[] ? U : never;

export const roundMoney = (m: number) => Math.round(m * Math.pow(10, 2)) / Math.pow(10, 2);

export function uuid() {
    return crypto.randomUUID();
}

export function generateKey() {
    return new Date().getTime();
}

// @ts-ignore
export function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

/**
 * Calculates percentage for a value as an integer in range [0, 100]
 * @param   {number} total Total count of which the value is a fraction
 * @param   {number} value The value to calculate percentage for
 * @returns {number}       Percentage as integer in range [0, 100]
 */
export function toPercent(total: number, value: number): number {
    if (!total) {
        return 0;
    }
    const p = Math.round((value / total) * 100);

    return p;
}

export const getNextOrdinalNumber = (input: Ordinal[]) => {
    let maxOrdinal = -1;
    input?.map((item) => {
        if (item.ordinal > maxOrdinal) {
            maxOrdinal = item.ordinal;
        }
    });
    return maxOrdinal + 1;
};

export const compareByOrdinal = (i: Ordinal, j: Ordinal) => {
    if (i.ordinal > j.ordinal) {
        return 1;
    } else if (i.ordinal < j.ordinal) {
        return -1;
    } else {
        return 0;
    }
};

export function getLocale() {
    return window.navigator.language;
}

export async function postToDownload(
    url: string,
    input: unknown,
    config: AxiosRequestConfig<unknown> | undefined,
    outputFileName: string
) {
    const response = await axios.post<Blob>(url, input, config);

    downloadBlob(outputFileName, response.data);
}

export async function downloadBlob(fileName: string, blob: Blob) {
    const objUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = objUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

/**
 * Used with URLSearchParam, takes an incoming object, removes any null or unknown params, returns a record
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toSearchParams(obj: any): Record<string, string> {
    const newObj = Object.entries(obj).reduce((prev, [key, value]) => {
        if (value != null) {
            prev[key] = value;
        }
        return prev;
    }, {} as typeof obj);
    return newObj;
}

/**
 * Calculates percentages for array of numbers as integers in range [0, 100].
 *
 * Based on https://stackoverflow.com/a/13483486
 * @param   {number[]} data array of numbers to calculate percentages for
 * @returns {number[]}      array of percentages for input numbers in original order summing up to 100
 * @example
 * const numbers = [189, 90, 90];
 * const percentages = toPercentages(numbers); // [51, 25, 24]
 */
export const toPercentages = (data: number[]): number[] => {
    const total = data.reduce((sum: number, dataItem: number) => sum + dataItem || 0, 0);

    let baseline = 0;
    let prev = 0;
    return data.map((dataItem) => {
        if (!total) {
            return 0;
        }
        const percentage = (dataItem / total) * 100;
        const percentageRounded = percentage + prev;
        prev += percentage;
        const result = Number.parseFloat((percentageRounded - baseline).toFixed(1));
        baseline = percentageRounded;
        return result;
    });
};

export const areChartItemsEqual = (
    items1: FinancialsWidgetChartItemCreateModel[] | null | undefined,
    items2: FinancialsWidgetChartItemCreateModel[] | null | undefined
) => {
    if (!!items1 && !!items2) {
        if (items1.length !== items2.length) return false;
        for (const item of items1) {
            const matchingItem = items2.find((i) => i.ordinal === item.ordinal);
            if (!matchingItem || matchingItem.name !== item.name || matchingItem.value !== item.value) return false;
        }
        return true;
    }
    return !!items1 === !!items2;
};

export const commaSeparatedNumber = (number: string | number): string =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const isValidUrl = (url: string): boolean => {
    const validationRegex = /^http[s]?:\/\//;
    return validationRegex.test(url);
};

export const isValidEmail = (email: string): boolean => {
    const validationRegex = /^\w+([.-]?\w+)+@\w+([.:]?\w+)+(\.[a-zA-Z0-9]{2,})+$/;
    return validationRegex.test(email);
};

export function isEllipsisActive(e: HTMLElement | null) {
    return !!e && e.offsetWidth < e.scrollWidth;
}

export const isUserInternal = () => {
    const user = useAccountUser();
    return user?.userType === 'Internal';
};

export const getCatActivityPathForUser = (isInternal: boolean, activityUrl: string) => {
    if (!isInternal) {
        return activityUrl.replace('/fac/', '/client/');
    }
    return activityUrl;
};

export const getApiErrors = <TKey extends string>(error: AxiosError<ApiResultError>): ApiErrors<TKey> => {
    const res: ApiErrors<TKey> = {};
    if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([k, v]) => {
            res[k as TKey] = v.errors;
        });
    }
    return res;
};

/**
 * Takes an AxiosError and returns the status number
 * @param error
 * @returns
 */
export const getApiErrorStatus = (error: AxiosError<ApiResultError>): number | undefined => {
    return error.response?.status;
};

/**
 * Takes an AxiosError and returns the status number
 * @param error
 * @returns
 */
export const getApiErrorStatusSimple = (error: AxiosError): number | undefined => {
    return error.response?.status;
};

/**
 * Takes an unknown Error and returns the message
 * @param error
 * @returns
 */
export const getApiErrorMessage = (e?: unknown | null, defaultMessage?: string | undefined): string | undefined => {
    if (!e) {
        return undefined;
    }

    if (e instanceof AxiosError && e.response) {
        const rsp = e.response;
        const data = rsp.data as StandardErrorData;
        if (data?.title) {
            return data.title;
        }
    }

    const p = e as PError;
    if (p && p.response && p.response.data?.title) {
        return p.response.data.title;
    }

    return defaultMessage;
};

/**
 * Takes an error and checks if it contains field errors
 * @param error
 * @returns boolean
 */
export const hasFieldErrors = (e?: unknown | null): boolean => {
    if (!e) {
        return false;
    }

    if (e instanceof AxiosError) {
        const error = (e.response?.data as ApiResultError)?.errors;
        if (error) {
            // the error object can be missing, or return {}.
            return Object.keys(error).length !== 0;
        }
        return false;
    }

    return false;
};

/**
 * takes in html text and returns only the text without any html markup
 * note: this does not give new lines, all the text will be on a single line
 * @param text
 * @returns plain text
 */
export const extractTextFromHtml = (text: string): string => {
    try {
        const d = document.createElement('div');
        d.innerHTML = text;

        return d.textContent ?? d.innerText;
    } catch {
        return '';
    }
};

export type FileSizeUnits = 'Gb' | 'Mb' | 'Kb';

/**
 * Converts a value from a specified file size unit to bytes.
 * @param value - The value to convert.
 * @param units - The file size unit ('Gb', 'Mb', or 'Kb').
 * @returns The converted value in bytes.
 */
export const convertToBytes = (value: number, units: FileSizeUnits) => {
    switch (units) {
        case 'Gb':
            return value * 1e9;
        case 'Mb':
            return value * 1e6;
        case 'Kb':
            return value * 1e3;
        default:
            throw new Error('Invalid file size unit. Supported units: Gb, Mb, Kb.');
    }
};

/**
 * Removes the extension from a file name.
 *
 * @param fileName - The name of the file.
 * @returns The file name without the extension.
 */
export function removeExtension(fileName: string) {
    const lastDotPosition = fileName.lastIndexOf('.');
    if (lastDotPosition === -1) return fileName; // Return original file name if no extension found
    return fileName.substring(0, lastDotPosition);
}

/**
 * Gets the extension from a file name.
 *
 * @param fileName - The name of the file.
 * @returns The extension of the file.
 */
export function getExtension(fileName: string) {
    const lastDotPosition = fileName.lastIndexOf('.');
    if (lastDotPosition === -1) return ''; // Return empty string if no extension found
    return fileName.substring(lastDotPosition + 1);
}

/**
 * Checks if document storage is configured.
 * @returns Returns true if document storage is configured, false otherwise.
 */
export function isDocumentStorageConfigured(
    documentStorageSettings: AccountDocumentStorageViewModel | undefined
): boolean {
    return !!documentStorageSettings && documentStorageSettings.accountDocumentStorageType !== 'None';
}
