export const Container = 'text-black p-[1em] flex flex-col rounded';

export const CharactersRemaining = 'text-xs';

export const Form = (gapSize?: number) => `flex flex-col gap-${gapSize ?? 1}`;

export const Field = 'p-1 flex flex-col mb-8';

export const FieldInLine = 'p-1 flex flex-col';

export const FieldGroup = 'flex flex-row';

export const Label = 'label';

export const Input = 'rounded-sm border-primary-50 disabled:text-inactiveSecondary read-only:text-inactiveSecondary';

export const SearchInput = 'block border border-primary-50 text-sm rounded-sm focus:border-primary-500';

export const InputError = 'bg-error-50 rounded-sm border-error-700 text-error-700';

export const FormError = 'bg-error-50 border border-error-700 text-error-700 px-4 py-3 rounded-sm relative';

export const FieldError = '';


export const Checkbox = 'h-4 w-4 rounded bg-background border border-primary-100 checked:border-primary-500 checked:text-primary-500 cursor-pointer';
export const CheckboxReadonly = 'h-4 w-4 rounded border read-only:checked:!bg-primary-100 read-only:checked:border-primary-100 read-only:!bg-primary-50 read-only:border-primary-50 cursor-not-allowed';
